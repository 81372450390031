<!--门禁模块入口-->
<template>
    <div id="doorindex" class="doorindex">
        <div class="header">
          <my-indextitle :imgurl="imgurl">{{ title }}</my-indextitle>
          <my-navdar :list="list"></my-navdar>
        </div>
        <div class="main">
            <transition name="slide-fade" mode="out-in">
                <keep-alive>
                    <router-view class="view"></router-view>
                </keep-alive>
            </transition>
        </div>
    </div>
  </template>
  
<script>
import indexTitle from '../../components/Title/indexTitle'
import Navdar from '../../components/Navdar/horizontal'

export default {
  name:'doorindex',
  data() {
    return {
      imgurl: require('../../../public/images/moduleimg/door.png'),
      list: JSON.parse(sessionStorage.getItem("sitemenu")) || []
    }
  },
  watch: {
    // 监听多语言切换，导航栏数据同步切换
    '$store.getters.loading': {
      handler: function (newValue, oldValue) {
        this.getMeunData()
      },
      deep: true
    }
  },
  // 获取名称
  computed: {
    title: function () {
      let name = this.$store.getters.siteName
      return name
    }
  },
  // 导航栏数据初始化
  mounted() {
    this.getMeunData()
  },
  methods:{
    // 获取导航数据（分页模块数据）数据
    getMeunData(){
      const value = this.$store.getters.loading
      const Code = this.$route.query.Code
      if (!value) {
        this.$store.commit("menu/SITE_MENU", Code)
        this.list = this.$store.getters.siteMenu
      }
    }
  },
  components: {
    'my-indextitle': indexTitle,
    'my-navdar': Navdar
  }
}
</script>

<style scoped>
#doorindex {
  display: flex;
  flex-direction: column;
  background: #ffffff;
  width: 100%;
  height: 100%;
  box-shadow: 0px 0px 10px #bdbcbc;
  border-radius: 3px;
  overflow: hidden;
}
.main {
  position: relative;
  width: 100%;
  height: 100%;
  overflow: hidden;
}
.header {
  height: 100px;
  padding: 0px 20px 0px 20px;
}
.view{
  position: absolute;
  width: 100%;
  height: 100%;
}
.slide-fade-enter-active {
  transition: all 0.8s ease;
}
.slide-fade-leave-active {
  transition: all 0.3s cubic-bezier(1, 0.5, 0.8, 1);
}
.slide-fade-enter,
.slide-fade-leave-to {
  transform: translateX(10px);
  opacity: 0;
}
</style>